<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Users</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Users
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Users</h4>
                </div>

                <div class="card-body">
                    <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allUsers"
                    >

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span>
                            <span v-else-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Name'">
                                {{ props.row.name }}
                            </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
											<a id="btnSearchDrop2" data-toggle="dropdown" aria-haspopup="true"
                                               aria-expanded="false" class="dropdown-toggle dropdown-menu-right"><i
                                                class="fa fa-ellipsis-v"></i></a>
											<span aria-labelledby="btnSearchDrop2"
                                                  class="dropdown-menu mt-1 dropdown-menu-right"
                                                  x-placement="bottom-end"
                                                  style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 19px, 0px);"
                                                  x-out-of-boundaries="">
<!--												<a :href="route('admin.admins.edit', props.row.id)"-->
                                                <!--                                                   class="dropdown-item edit"><i class="feather icon-edit-2"></i>-->
                                                <!--													Edit</a>-->
											</span>
										</span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Users'},
    props: ['users'],
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allUsers: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'S/N', field: 'sn'},
            {label: 'Name', field: 'name'},
            {label: 'Email', field: 'email'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    mounted() {
        this.allUsers = this.users.data;
        this.totalRecords = this.users.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>
